import React from 'react';
import { Helmet } from 'react-helmet';
import '../styles/index.scss';
import * as styles from "../components/header.module.scss";
import Spacer from '../components/spacer';
import Header from '../components/header';
import Headline from '../components/headline';
import Lilschk from '../components/lilschk';
import ImageLilschk from '../images/about-lilschk.jpg';
import { motion } from "framer-motion";
import Form from "../components/form";
import Icon from "../images/icons/heart.png";



export default class FormBlock extends React.Component {
    constructor(props) {
      super(props)

      this.state = {
          formOpen: false,
            }
      }
      openForm = () => {
            if (this.state.formOpen === false){
                  {this.setState({ formOpen: true })}
            } else {this.setState({ formOpen: false })}
            console.log(this.state.formOpen);


      }
  
      render() {
        if (this.state.formOpen === false) 
          return <main>
                  <motion.main
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1}}
                  exit={{ opacity: 0}}
                  transition={{
                    type: "spring",
                    mass: 0.35,
                    stiffness: 75,
                    duration: 0.3
                  }}
                >
                  <Helmet>
                    <title>Lilschk Photography - Corporate Portfolio</title>
                  </Helmet>
                  <div className="fixed foreground">
                    <Header></Header>
                    <Lilschk></Lilschk>
                  </div>
                  <Headline title={"about"}></Headline>
                  <Spacer height={"400"}></Spacer>
                    <div className="flex layout">
                        <div className="flex">
                            <div className="text">
                              <p>Her mission is to capture emotional moments and inspire other people with her unique image style. lilschk wants to convey a specific mood and feeling to the audience with her photography. </p>
                              <a href="https://www.instagram.com/lilschk/" target="_blank">Instagram @Lilschk</a>
                              <p><button onClick={this.openForm}>
                                            write me!
                              </button></p>
                            
                            </div>
                            <div className="image"><img className="aboutImage" src={ImageLilschk}></img></div>
                        </div>
                    </div>
                  </motion.main>
                </main>
          else{
            return (
              <div>
                        <div className={[styles.top, styles.center, styles.background].join(' ')}>
                              <button  onClick={this.openForm}>
                                    <img className={styles.heart} src={Icon}></img>
                              </button>
                        </div>
                        <Form></Form>
                        </div>
            )
          }
}}
